<template>
    <!-- <nav class="flex justify-center py-4">
        <router-link to="/" class="mr-6 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Home</router-link>
        <router-link to="/about" class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">About</router-link>
    </nav> -->
</template>

<script>
// import { RouterLink } from 'vue-router'

export default {
  name: 'Navigation',
  data() {
    return {

    }
  },
}
</script>

