<template>
  <div class="bg h-full">

    <Head/>

    <Projects> </Projects>

  </div>
</template>

<script>
// @ is an alias to /src
import Projects from '@/components/Projects.vue'
import Head from '@/components/Head.vue'

export default {
  name: 'HomeView',
  components: {
    Projects,
    Head
  },
  
}
</script>

<style>
.bg {
  background: linear-gradient(135deg, #ff00ff, #00ffff);
  background: black;
}

</style>
