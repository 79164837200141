<template>

  <div class="flex justify-center items-center cursor-pointer">
    <img class="logo" :src="require('@/assets/logo_circle_white.png')" alt="Logo" width="300" height="300"
      @click="spinHead" @mouseenter="anticipateSpin" @mouseleave="returnSpin">
  </div>

</template>

<script>
// import { RouterLink } from 'vue-router'

export default {
  name: 'Navigation',
  data() {
    return {

    }
  },
  methods: {
    spinHead() {
      const logo = document.querySelector('.logo');
      logo.classList.add('spin');
      setTimeout(() => {
        logo.classList.remove('spin');
      }, 1000);
    },
    anticipateSpin() {
      const logo = document.querySelector('.logo');
      logo.classList.add('anticipate');
    },
    returnSpin() {
      const logo = document.querySelector('.logo');
      logo.classList.remove('anticipate');
    },
  }
}
</script>

<style scoped>

.logo {
  transition: transform 1s ease-in-out;
}

.anticipate {
  transform: rotate(10deg);
}

.spin {
  transform: rotate(360deg);
}

</style>