<template>
  <div class="flex flex-wrap justify-center mt-8">
    <div class="flex mb-4 overflow-x-auto">
      <button
        @click="currentCategory = -1"
        :class="{
          'bg-gradient-to-r from-yellow-400 to-pink-500':
            currentCategory === -1,
        }"
        class="px-4 rounded-lg py-2 bg-white text-xl mr-4 hover:bg-red-300 transition-colors duration-300 ease-in-out"
      >
        #all
      </button>
      <button
        v-for="category in categories"
        :key="category.ID"
        @click="currentCategory = category.ID"
        :class="{
          'bg-gradient-to-r from-yellow-400 to-pink-500':
            currentCategory === category.ID,
        }"
        class="px-4 rounded-lg py-2 bg-white text-xl mr-4 hover:bg-red-300 transition-colors duration-300 ease-in-out"
      >
        {{ category.name }}
      </button>
    </div>
  </div>

  <div class="flex flex-wrap justify-center">
    <div class="flex flex-wrap justify-center w-full">
      <div
        v-for="(project, index) in filteredProjects"
        :key="project.ID"
        class="project-box shadow-md bg-white rounded-lg overflow-hidden m-4 w-full md:w-1/3 max-w-7xl"
      >
        <a :href="project.link" target="_blank">
          <div class="flex justify-start items-center p-4 h-full">
            <div class="flex items-center justify-center w-1/6">
              <div class="text-4xl h-full flex items-center">
                {{ project.emoji }}
              </div>
            </div>
            <div class="pl-4 w-5/6">
              <div class="text-lg font-bold flex justify-between items-center">
                {{ project.title }}
                <div class="bg-gray-300 text-sm px-2 rounded-md">
                  {{ formatDate(project.date) }}
                </div>
              </div>
              <div class="text-sm">{{ project.text }}</div>
            </div>
          </div>
        </a>
      </div>
      <!-- Add invisible box if there's an odd number of projects -->
      <div
        class="invisible project-box shadow-md bg-white rounded-lg overflow-hidden m-4 w-full md:w-1/3 max-w-7xl"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",
  data() {
    return {
      projects: [],
      categories: [],
      currentCategory: 1,
    };
  },
  mounted() {
    this.getProjects();
    this.getCategories();
  },
  methods: {
    async getProjects() {
      try {
        const response = await fetch(
          "https://www.nickschnee.ch/php/getProjects.php"
        );
        const data = await response.json();
        this.projects = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCategories() {
      try {
        const response = await fetch(
          "https://www.nickschnee.ch/php/getCategories.php"
        );
        const data = await response.json();
        this.categories = data.sort((a, b) => a.sorter - b.sorter);
      } catch (error) {
        console.error(error);
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.getFullYear();
    },
  },
  computed: {
    filteredProjects() {
      if (this.currentCategory == -1) {
        // then it's the all category and display all projects by date
        return this.projects.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
      }
      return this.projects
        .filter((project) => {
          return (
            project.category_1 === this.currentCategory ||
            project.category_2 === this.currentCategory
          );
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .flex::-webkit-scrollbar {
    display: none;
  }
}

.project-box {
  transition: transform 0.4s ease-in-out;
}

.project-box:hover {
  transform: translateY(-5px);
}
</style>
